import store from "../index";
import { LOGGED_USER_DETAILS, RESET_USER_DETAILS } from "../types";

export const saveUserDetails = (payload) => {
  return {
    type: LOGGED_USER_DETAILS,
    payload,
  };
};
export const resetUserDetails = (payload) => {
  return {
    type: RESET_USER_DETAILS,
    payload,
  };
};

export const dispatchUserDetails = (key, value) => {
  console.log("key value", key, value);
  let obj = {
    [key]: value,
    // value: value,
  };
  store.dispatch(saveUserDetails(obj));
};
