import React, { useState } from "react";
import { Link } from "react-router-dom";
import Loader from "./Loader";

export default function Login() {
  const [loading, setLoading] = useState(false);

  const handleLogin = () => {
    setLoading(true);
    try {
      // Redirect the user to Twitter for authentication
      window.location.href = `${process.env.REACT_APP_API_URL}/auth/twitter`;
      setTimeout(() => {
        setLoading(false);
      }, [2000]);
    } catch (error) {
      console.error("Error during Twitter sign-in", error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-background">
      <div className="w-full max-w-md p-6 space-y-4">
        <div className="flex justify-center">
          <TwitterIcon className="h-12 w-12 text-blue-500" />
        </div>
        <h1 className="text-3xl  font-bold text-center">Twitter Scheduler</h1>
        {/* <div className="space-y-2">
          <label htmlFor="email">Email</label>
          <input id="email" type="email" placeholder="Enter your email" />
        </div> */}
        {/* <div className="space-y-2">
          <div className="flex items-center justify-between">
            <label htmlFor="password">Password</label>
            <Link to="#" className="text-primary-foreground hover:underline">
              Forgot Password?
            </Link>
          </div>
          <input
            id="password"
            type="password"
            placeholder="Enter your password"
          />
        </div> */}
        <button
          type="button"
          onClick={handleLogin}
          className="w-full px-4 border-solid border-0 bg-blue-500 text-white py-3 text-lg rounded-xl my-4"
        >
          {loading ? <Loader /> : "Log In with Twitter"}
        </button>
        {/* <div className="text-center text-muted-foreground">
          Don't have an account?{" "}
          <Link to="#" className="text-primary-foreground hover:underline">
            Sign Up
          </Link>
        </div> */}
      </div>
    </div>
  );
}

function TwitterIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z" />
    </svg>
  );
}
