import { GoogleGenerativeAI } from "@google/generative-ai";

export const GenAI = async (userPropmt) => {
  const prompt = `Write a one liner tweet to post on twitter. Given is the user prompt for generating the tweet. ${userPropmt}`;

  console.log("finalPrompt", prompt);
  console.log("process.env  - ", process.env.REACT_APP_GENERATIVE_AI_API_KEY);

  const genAI = new GoogleGenerativeAI(
    process.env.REACT_APP_GENERATIVE_AI_API_KEY
  );
  const model = genAI.getGenerativeModel({ model: "gemini-pro" });
  const result = await model.generateContent(prompt);
  console.log("result", result);
  return result;
};
