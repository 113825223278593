import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { GenAI } from "../GenerativeAI/GenAI";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";

export default function Tweet() {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const [generatedTweet, setGeneratedTweet] = useState(false);
  const [loadingContent, setLoadingContent] = useState(false);
  const [loadingContentPost, setLoadingContentPost] = useState(false);
  const [displayedContent, setDisplayedContent] = useState("");

  const navigate = useNavigate();

  const userDetails = useSelector((state) => state?.root?.userDetails);
  console.log("userDetails", userDetails);

  const generateTweet = async (data) => {
    setLoadingContent(true);
    const generatedResponse = await GenAI(data?.prompt);
    let generativeContent =
      generatedResponse?.response?.candidates[0]?.content?.parts[0]?.text;

    if (generativeContent) {
      // Remove stars from the content
      generativeContent = generativeContent.replace(/\*/g, "");
      setLoadingContent(false);

      // Split the content into an array of lines
      let lines = [];
      try {
        lines = generativeContent.split("\n");
      } catch (error) {
        console.error("Error splitting content into lines:", error);
      }

      // Initialize a variable to keep track of the current displayed content
      let currentContent = "";

      // Loop through each line if lines array is defined
      if (lines) {
        for (let i = 0; i < lines.length; i++) {
          // Split the line into an array of characters
          let characters = [];
          try {
            characters = lines[i].split("");
          } catch (error) {
            console.error("Error splitting line into characters:", error);
          }

          // Loop through each character
          for (let j = 0; j < characters.length; j++) {
            // Append the next character to the current content
            currentContent += characters[j];

            // Update the displayed content
            setDisplayedContent(currentContent);
            setGeneratedTweet(true);

            // Add a delay between each character
            await new Promise((resolve) => setTimeout(resolve, 20)); // Adjust the delay (50ms = 0.05 seconds)
          }

          // Add a newline character after each line
          currentContent += "\n";
        }
      }
    }
  };

  const handleTweet = async () => {
    setLoadingContentPost(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/tweet",
        { text: displayedContent },
        {
          headers: {
            Authorization: `Bearer ${userDetails?.token}`,
          },
        }
      );

      console.log("Tweet response:", response.data);
      setLoadingContentPost(false);
      // setTweetText(''); // Clear tweet input field after successful post
      // Handle successful tweet response (e.g., display success message)
    } catch (error) {
      console.error("Error tweeting:", error);
      setLoadingContentPost(false);
      // Handle tweet submission error (e.g., display error message)
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-background">
      <header className="bg-blue-500 text-white text-primary-foreground py-4 px-6">
        <div className="container mx-auto flex items-center justify-between">
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => navigate("/")}
          >
            <TwitterIcon className="h-6 w-6" />
            <h1 className="text-2xl font-bold">Tweet Generator</h1>
          </div>
          <p className="text-sm text-primary-foreground/80">
            Generate unique and engaging tweets with our AI-powered tool.
          </p>
        </div>
      </header>
      <main className="flex-1 container mx-auto py-12 px-4 md:px-6">
        <div className="max-w-2xl mx-auto grid gap-8">
          <form onSubmit={handleSubmit(generateTweet)}>
            <div className="grid gap-2">
              <label htmlFor="tweet-text" className="text-sm font-medium">
                Enter your prompt here:
              </label>
              <div className="relative">
                <textarea
                  id="tweet-text"
                  placeholder="Type your prompt here..."
                  className="w-full rounded-md border border-input bg-background px-4 py-2 text-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary"
                  rows={3}
                  {...register("prompt", {
                    required: "Field is required",
                  })}
                />
                {/* <div className="absolute right-2 top-2 text-sm text-muted-foreground">
                140
              </div> */}
                {errors?.prompt && (
                  <p className="text-red-500">{errors?.prompt?.message}</p>
                )}
              </div>
              <button className="justify-center bg-blue-500 text-white py-2 rounded-lg">
                {loadingContent ? <Loader /> : "Generate Tweet"}
              </button>
            </div>
          </form>
          {generatedTweet && (
            <>
              <div className="bg-slate-100 rounded-md p-5">
                <h2 className="text-lg font-medium mb-2">Generated Tweet:</h2>

                <div className="text-slate-600 whitespace-pre-wrap break-words">
                  {displayedContent}
                </div>
              </div>

              <div>
                <button
                  type="button"
                  onClick={handleTweet}
                  className="w-full bg-blue-500 text-white py-2 rounded-lg"
                >
                  {loadingContentPost ? <Loader /> : "Tweet Now"}
                </button>
              </div>
            </>
          )}
        </div>
      </main>
      <footer className="bg-slate-100 text-slate-500 text-muted-foreground py-4 px-6">
        <div className="container mx-auto text-center text-sm">
          &copy; 2024 Tweet Generator. All rights reserved.
        </div>
      </footer>
    </div>
  );
}

function TwitterIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z" />
    </svg>
  );
}
