import { useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import { GenAI } from "../GenerativeAI/GenAI";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";

export default function TweetSchedule() {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const {
    handleSubmit: handleSubmit1,
    register: register1,
    formState: { errors: errors1 },
  } = useForm({ mode: "onBlur" });

  const [startDate, setStartDate] = useState(null);
  const [generatedTweet, setGeneratedTweet] = useState(false);
  const [loadingContent, setLoadingContent] = useState(false);
  const [tweetScheduleContent, setTweetScheduleContent] = useState(false);
  const [displayedContent, setDisplayedContent] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const userDetails = useSelector((state) => state?.root?.userDetails);

  const generateTweet = async (data) => {
    setLoadingContent(true);
    console.log("generated data", data);
    const generatedResponse = await GenAI(data?.prompt);
    let generativeContent =
      generatedResponse?.response?.candidates[0]?.content?.parts[0]?.text;

    if (generativeContent) {
      // Remove stars from the content
      generativeContent = generativeContent.replace(/\*/g, "");
      setLoadingContent(false);

      // Split the content into an array of lines
      let lines = [];
      try {
        lines = generativeContent.split("\n");
      } catch (error) {
        console.error("Error splitting content into lines:", error);
      }

      // Initialize a variable to keep track of the current displayed content
      let currentContent = "";

      // Loop through each line if lines array is defined
      if (lines) {
        for (let i = 0; i < lines.length; i++) {
          // Split the line into an array of characters
          let characters = [];
          try {
            characters = lines[i].split("");
          } catch (error) {
            console.error("Error splitting line into characters:", error);
          }

          // Loop through each character
          for (let j = 0; j < characters.length; j++) {
            // Append the next character to the current content
            currentContent += characters[j];

            // Update the displayed content
            setDisplayedContent(currentContent);
            setGeneratedTweet(true);

            // Add a delay between each character
            await new Promise((resolve) => setTimeout(resolve, 20)); // Adjust the delay (50ms = 0.05 seconds)
          }

          // Add a newline character after each line
          currentContent += "\n";
        }
      }
    }
  };

  const scheduleTweet = async (data) => {
    setTweetScheduleContent(true);
    console.log("scheduleTweet", data);
    const combinedDateTime = new Date(
      `${data?.scheduleDate}T${data?.scheduleTime}`
    ).toISOString();
    console.log("combinedDateTime", combinedDateTime);

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/schedule-tweet",
        // { text: displayedContent },
        {
          tweetContent: displayedContent,
          scheduledTime: combinedDateTime,
          username: "AshwinS71",
        },
        {
          headers: {
            Authorization: `Bearer ${userDetails?.token}`,
          },
        }
      );
      setTweetScheduleContent(false);
      console.log("Tweet response:", response.data);
      // setTweetText(''); // Clear tweet input field after successful post
      // Handle successful tweet response (e.g., display success message)
    } catch (error) {
      console.error("Error tweeting:", error);
      setTweetScheduleContent(false);
      // Handle tweet submission error (e.g., display error message)
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-background">
      <header className="bg-blue-500 text-white text-primary-foreground py-4 px-6">
        <div className="container mx-auto flex items-center justify-between">
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => navigate("/")}
          >
            <TwitterIcon className="h-6 w-6" />
            <h1 className="text-2xl font-bold ">Tweet Generator</h1>
          </div>
          <p className="text-sm text-primary-foreground/80">
            Generate unique and engaging tweets with our AI-powered tool.
          </p>
        </div>
      </header>
      <div className="w-full max-w-xl mx-auto py-12 px-4 md:px-6">
        <div className="space-y-4 text-center">
          <h1 className="text-3xl font-bold">Schedule a Tweet</h1>
          <p className="text-muted-foreground">
            Easily schedule your tweets to be posted at the perfect time.
          </p>
        </div>
        <form onSubmit={handleSubmit(generateTweet)} className="mt-8 space-y-6">
          <div className="space-y-2">
            <label htmlFor="tweet-text font-medium">Enter Prompt</label>
            <textarea
              id="tweet-text"
              {...register("prompt", {
                required: "Field is required",
              })}
              placeholder="What's on your mind?"
              className="w-full rounded-md border border-input bg-background px-4 py-2 text-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary"
              rows={3}
            />
            {errors?.prompt && (
              <p className="text-red-600">{errors?.prompt?.message}</p>
            )}
          </div>
          <div>
            {" "}
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 rounded-lg"
            >
              {loadingContent ? (
                //   <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">

                <Loader />
              ) : (
                //   </svg>
                "Generate Tweet"
              )}
            </button>
          </div>
        </form>
        {generatedTweet && (
          <div className="bg-slate-100 rounded-md p-5 my-4">
            <h2 className="text-lg font-medium mb-2">Generated Tweet:</h2>
            <div className="text-slate-600 whitespace-pre-wrap break-words">
              {displayedContent}
            </div>
          </div>
        )}
        <form onSubmit={handleSubmit1(scheduleTweet)}>
          <div className="grid grid-cols-2 gap-4 my-4">
            <div className="space-y-2">
              <label htmlFor="tweet-date">Date</label>
              {/* <Popover>
              <Popover.Button
                as="button"
                variant="outline"
                className="w-full justify-start font-normal"
              >
                Pick a date
              </Popover.Button>
              <Transition
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel className="w-auto p-0">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="w-full p-2 border rounded"
                    inline
                  />
                </Popover.Panel>
              </Transition>
            </Popover> */}
              <input
                className="border-solid border-2 rounded-md mx-2"
                type="date"
                {...register1("scheduleDate", {
                  required: "Field is required",
                })}
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="tweet-time">Time</label>
              <input
                className="border-solid border-2 rounded-md mx-2"
                id="tweet-time"
                type="time"
                {...register1("scheduleTime", {
                  required: "Field is required",
                })}
                placeholder="Select time"
              />
            </div>
          </div>
          {/* <div className="space-y-2">
          <label htmlFor="tweet-image">Attach Image</label>
          <input id="tweet-image" type="file" />
        </div> */}
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded-lg"
          >
            {tweetScheduleContent ? <Loader /> : "Schedule Tweet"}
          </button>
        </form>
      </div>
    </div>
  );
}

function TwitterIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z" />
    </svg>
  );
}
