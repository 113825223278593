import { LOGGED_USER_DETAILS, RESET_USER_DETAILS } from "../types";

const initialState = {
  userDetails: {},
};

const userReducers = (state = initialState, action) => {
  switch (action.type) {
    case LOGGED_USER_DETAILS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ...action.payload,
        },
      };
    case RESET_USER_DETAILS:
      return {
        ...state,
        userDetails: {},
      };
    default:
      return state;
  }
};

export default userReducers;
