import "./index.css";
import { Navigate, Route, Routes } from "react-router-dom";
import Homepage from "./components/Homepage";
import Login from "./components/Login";
import Ask from "./components/Ask";
import Tweet from "./components/Tweet";
import TweetSchedule from "./components/TweetSchedule";
import { useSelector } from "react-redux";

function App() {
  const userDetails = useSelector((state) => state?.root?.userDetails);
  console.log("userDetails", userDetails);
  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/ask" element={<Ask />} />
      <Route
        path="/tweet"
        element={
          userDetails?.username && userDetails?.token ? (
            <Tweet />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="/tweet-schedule"
        element={
          userDetails?.username && userDetails?.token ? (
            <TweetSchedule />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
    </Routes>
  );
}

export default App;
