import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { dispatchUserDetails } from "../store/action/userAction";

export default function Ask() {
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const extractId = new URLSearchParams(window.location.search).get("id");
    console.log("extractId", extractId);
    setUserId(extractId);
  }, []);

  useEffect(() => {
    getUserData();
  }, [userId]);

  const getUserData = async () => {
    {
      try {
        if (userId) {
          const response = await axios.get(
            process.env.REACT_APP_API_URL + `/auth/getUserData/${userId}`
          );
          console.log("response", response);
          if (response?.data?.status) {
            dispatchUserDetails("username", response?.data?.username);
            dispatchUserDetails("token", response?.data?.token);
            dispatchUserDetails(
              "twitterProvider",
              response?.data?.twitterProvider
            );
          }
        }
      } catch (error) {
        console.log("Error getting the user data", error);
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gradient-to-r to-blue-500 from-cyan-300 ">
      <div className="max-w-md px-6 py-12 bg-background rounded-lg shadow-xl bg-white">
        <h1 className="text-3xl font-bold  mb-4 text-blue-400">
          Twitter Your Thoughts
        </h1>
        <p className="text-slate-400 mb-8">
          Choose when to share your thoughts on Twitter.
        </p>
        <div className="flex flex-col gap-4 sm:flex-row ">
          <Link
            to="/tweet"
            className="inline-flex h-10 hover:bg-blue-500 hover:text-white hover:cursor-pointer items-center justify-center rounded-md bg-primary px-6 text-sm font-medium text-primary-foreground shadow transition-colors hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
          >
            Tweet Now
          </Link>
          <Link
            to="/tweet-schedule"
            className="inline-flex h-10 hover:bg-blue-500 hover:cursor-pointer items-center justify-center rounded-md border border-input bg-background px-6 text-sm font-medium shadow-sm transition-colors hover:bg-accent hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
          >
            Tweet Later
          </Link>
        </div>
      </div>
    </div>
  );
}
