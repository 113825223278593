import React from "react";
import { Link, useNavigate } from "react-router-dom";

function Homepage() {
  const navigate = useNavigate();
  return (
    <div className="flex min-h-[100vh] flex-col">
      <header className="bg-blue-500 py-6 px-4 md:px-6">
        <div className="container mx-auto flex max-w-5xl items-center justify-between">
          <Link to="/" className="text-primary-foreground">
            <TwitterIcon className="h-6 w-6 text-white" />
            <span className="sr-only">Twitter Scheduler</span>
          </Link>
          <div className="flex items-center gap-4">
          <button>
            <p className="text-sm  text-white font-medium text-primary-foreground hover:underline"
            onClick={() => navigate('/login')}
            >
              Log In
            </p>
          </button>
            <button>
              <p className="text-sm text-white font-medium text-primary-foreground hover:underline"
              onClick={() => navigate('/login')}
              >Sign Up</p>
            </button>
          </div>
        </div>
      </header>
      <main className="flex-1">
        <section className="bg-blue-500 py-12 md:py-24 lg:py-32">
          <div className="container mx-auto max-w-5xl px-4 md:px-6">
            <div className="grid gap-6 lg:grid-cols-2 lg:gap-12">
              <div className="flex flex-col items-start justify-center space-y-6">
                <h1 className="text-4xl text-white font-bold tracking-tighter text-primary-foreground sm:text-5xl md:text-6xl">
                  Effortlessly Schedule and Generate Tweets
                </h1>
                <p className="max-w-[600px] text-white text-lg text-primary-foreground">
                  Streamline your Twitter presence with our powerful scheduling
                  and generation tools. Save time, boost engagement, and grow
                  your audience.
                </p>
                <button className=" border-solid border-2 bg-white rounded-md px-4 text-blue-600 py-2 text-primary hover:bg-blue-500-foreground/90">
                  <Link to="/login" className="text-sm font-medium">
                    Sign Up for Free
                  </Link>
                </button>
              </div>
              <div className="flex justify-center">
                <img
                  src="/assets/images/images.png"
                  width={600}
                  height={400}
                  alt="Twitter Scheduler"
                  className="rounded-xl"
                  style={{ aspectRatio: "600/400", objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="py-12 md:py-24 lg:py-32">
          <div className="container mx-auto max-w-5xl px-4 md:px-6">
            <div className="grid gap-12 lg:grid-cols-2">
              <div>
                <h2 className="mb-4 text-3xl font-bold tracking-tighter">
                  Schedule Tweets with Ease
                </h2>
                <p className="text-muted-foreground">
                  Our intuitive scheduling tool allows you to plan and queue
                  your tweets in advance. Never miss a beat in your Twitter
                  strategy.
                </p>
                <button variant="link" className="mt-4">
                  Learn More
                </button>
              </div>
              <div>
                <h2 className="mb-4 text-3xl font-bold tracking-tighter">
                  Generate Engaging Tweet Ideas
                </h2>
                <p className="text-muted-foreground">
                  Struggling to come up with fresh tweet ideas? Our AI-powered
                  generator provides personalized suggestions to keep your
                  content engaging and relevant.
                </p>
                <button variant="link" className="mt-4">
                  Learn More
                </button>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="bg-muted py-12 md:py-24 lg:py-32">
          <div className="container mx-auto max-w-5xl px-4 md:px-6">
            <div className="grid gap-12 lg:grid-cols-2">
              <div>
                <h2 className="mb-4 text-3xl font-bold tracking-tighter">
                  Analyze Your Twitter Performance
                </h2>
                <p className="text-muted-foreground">
                  Gain valuable insights into your Twitter activity with our
                  comprehensive analytics dashboard. Optimize your strategy and
                  drive better results.
                </p>
                <button variant="link" className="mt-4">
                  Learn More
                </button>
              </div>
              <div>
                <h2 className="mb-4 text-3xl font-bold tracking-tighter">
                  Collaborate with Your Team
                </h2>
                <p className="text-muted-foreground">
                  Seamlessly manage your Twitter presence with your team. Assign
                  tasks, review content, and stay aligned on your social media
                  strategy.
                </p>
                <button variant="link" className="mt-4">
                  Learn More
                </button>
              </div>
            </div>
          </div>
        </section> */}
      </main>
      <footer className="bg-blue-500 py-6 px-4 md:px-6">
        <div className="container mx-auto max-w-5xl flex items-center justify-between">
          <p className="text-sm text-white text-primary-foreground">
            &copy; 2024 Twitter Scheduler. All rights reserved.
          </p>
          <div className="flex items-center gap-4 text-white">
            <Link
              to="/terms"
              className="text-sm font-medium text-primary-foreground hover:underline"
            >
              Terms of Service
            </Link>
            <Link
              to="/privacy"
              className="text-sm font-medium text-primary-foreground hover:underline"
            >
              Privacy Policy
            </Link>
          </div>
        </div>
      </footer>
    </div>
  );
}

function TwitterIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z" />
    </svg>
  );
}

export default Homepage;
