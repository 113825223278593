import { createStore, applyMiddleware, combineReducers } from "redux";

import { composeWithDevTools } from "@redux-devtools/extension";
import userReducers from "./reducer/userReducer";
import { thunk } from "redux-thunk";
import { loadState } from "./localStorage";
const persistedState = loadState();
const rootReducer = combineReducers({
  root: userReducers,
});

const store = createStore(
  rootReducer,
  persistedState,
  composeWithDevTools(applyMiddleware(thunk))
);
export default store;
